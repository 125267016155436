import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '253, 236, 204',
		'primary-dark': '27, 25, 24',
		'accent': '195, 86, 105',
		'accent-plus': '255, 255, 255',
	},
});
